import axiosOrder from "../axios-order";
import { Routes } from "../components/Utils/CheckRoute";

export const CHANGE_THEME = "CHANGE_THEME";
export const CHANGE_THEME_FIRST_LOAD = "CHANGE_THEME_FIRST_LOAD";
export const CHANGE_SYMBOL = "CHANGE_SYMBOL";
export const IS_ADJUSTED = "IS_ADJUSTED";
export const GET_BULLISH_BEARISH = "GET_BULLISH_BEARISH";
export const CANDLE_PATTERN = "CANDLE_PATTERN";
export const SAVE_SETTING_STATE = "SAVE_SETTING_STATE";
export const CLEAR_CANDLE_PATTERN = "CLEAR_CANDLE_PATTERN";
export const CLEAR_BULLISH_BEARISH = "CLEAR_BULLISH_BEARISH";
export const SAVE_PORTFOLIO_STATE = "SAVE_PORTFOLIO_STATE";
export const GET_SUPPORT_RESISTANCE_LINE = "GET_SUPPORT_RESISTANCE_LINE";
export const GET_TREND_LINE = "GET_TREND_LINE";
export const SET_PRICE_ALERT_REFETCH_FLAG = 'SET_PRICE_ALERT_REFETCH_FLAG';

export const setBullishAndBearish = (data) => {
  return {
    type: GET_BULLISH_BEARISH,
    bullishBearishData: data,
  };
};

export const setSupportResistanceData = (data) => {
  return {
    type: GET_SUPPORT_RESISTANCE_LINE,
    supportResistanceData: data,
  };
};

export const setTrendLinesData = (data) => {
  return {
    type: GET_TREND_LINE,
    trendLineData: data,
  };
};

export const setUserTheme = (theme) => {
  return {
    type: CHANGE_THEME,
    theme: theme,
  };
};
export const fetchBullishAndBearish = (symbol, technicalName) => {
  return (dispatch) => {
    try {
      axiosOrder
        .get(
          "/tradingViewSystemxLite/getDivergence?symbol=" +
          symbol +
          "&technicalName=" +
          technicalName
        )
        .then((result) => {
          result = result.data;
          dispatch(setBullishAndBearish(result));
        })
        .catch((err) => {
          console.log(err);
          dispatch(setBullishAndBearish({}));
        });
    } catch (exception) {
      console.log(exception);
      setBullishAndBearish({});
    }
  };
};

export const fetchSupportResistance = (symbol) => {
  return (dispatch) => {
    try {
      axiosOrder
        .get("supportResistance/daily/" + symbol.toLocaleUpperCase())
        .then((result) => {
          result = result.data;
          if (result.data) {
            if (typeof result.data === "string") {
              dispatch(setSupportResistanceData(JSON.parse(result.data)));
            } else {
              dispatch(setSupportResistanceData(result.data));
            }
          } else {
            dispatch(setSupportResistanceData([]));
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(setSupportResistanceData([]));
        });
    } catch (exception) {
      console.log(exception);
      setSupportResistanceData([]);
    }
  };
};

export const fetchTrendLines = (symbol) => {
  return (dispatch) => {
    try {
      axiosOrder
        .get("trends/daily/" + symbol.toLocaleUpperCase())
        .then((result) => {
          result = result.data;
          if (result.data) {
            if (typeof result.data === "string") {
              dispatch(setTrendLinesData(JSON.parse(result.data)));
            } else {
              dispatch(setTrendLinesData(result.data));
            }
          } else {
            dispatch(setTrendLinesData([]));
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(setTrendLinesData([]));
        });
    } catch (exception) {
      console.log(exception);
      setTrendLinesData([]);
    }
  };
};

function getAuth() {
  if (
    localStorage.getItem("persist:systemx-lt-") !== undefined &&
    localStorage.getItem("persist:systemx-lt-") !== null
  ) {
    return JSON.parse(
      JSON.parse(localStorage.getItem("persist:systemx-lt-")).auth
    ).userId;
  }
}

export const saveUserTheme = (themeName) => {
  return (dispatch) => {
    axiosOrder
      .post("/navbar/" + Routes.changeTheme, {
        userId: getAuth(),
        themeName: themeName,
      })
      .then((result) => result.data)
      .then((result) => {
        dispatch(setUserTheme(result));
      })
      .catch((err) => dispatch(setUserTheme("Light")));
  };
};

const setCandlePatternInfo = (data) => {
  return {
    type: CANDLE_PATTERN,
    candlePattern: data,
  };
};
export const getCandlePatternInfo = (symbol) => {
  return (dispatch) => {
    axiosOrder
      .get("/tradingViewSystemxLite/getCandlePattern?symbol=" + symbol)
      .then((result) => result.data)
      .then((result) => {
        dispatch(setCandlePatternInfo(result));
      })
      .catch((err) => dispatch(setCandlePatternInfo([])));
  };
};
