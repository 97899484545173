/* eslint-disable*/
import * as React from "react";
import * as ReactDOM from "react-dom";

import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import { has, isString } from "lodash";
import axios from "axios";

import reducer from "./store/reducers";
import ChartRoutes from "./Routes/ChartRoutes";
import customAxios from "./axios-order";
import NotFound from "./components/NotFound";
import { StyleProvider } from '@ant-design/cssinjs';
import "./index.css";

// console.log = function () {
// }

const env_var =
  typeof window._env_ !== "undefined" ? window._env_ : process.env;
const loginBaseURL = env_var.LOGIN_SERVER_URL;

async function storeUserIdToLocalStorage() {
  localStorage.removeItem("tvTheme");
  localStorage.removeItem("source");
  try {
    if (window.location.search.length !== 0) {
      if (window.location.search.startsWith("?tk=")) {
        const token = window.location.search.split("?tk=")[1];
        let bytes = window.atob(token);
        let originalText = JSON.parse(bytes);
        const auth = {
          userId: parseInt(originalText.userId),
          token: originalText.token,
          authentication: true,
        };

        const item = {
          auth: JSON.stringify(auth),
        };

        const ticker = originalText.ticker;
        if (ticker) {
          localStorage.setItem("symbol", ticker + "_adj");
        }

        localStorage.removeItem("persist:systemx-lt-");
        localStorage.setItem("persist:systemx-lt-", JSON.stringify(item));
        isUserValid(window.btoa(JSON.stringify(auth)))
          .then((result) => {
            if (result === true) {
              init();
            } else {
              showErrorPage();
            }
          })
          .catch((ex) => {
            console.log(ex);
            showErrorPage();
          });
      } else if (window.location.search.startsWith("?t=")) {
        const token = window.location.search.split("?t=")[1];
        getIdFromTokenSystemx(token)
          .then((result) => {
            return init();
          })
          .catch((ex) => {
            return showErrorPage();
          });
      } else if (window.location.search.startsWith("?tkr")) {
        const params = new URLSearchParams(window.location.search);
        if (
          params.get("tkr") !== null ||
          typeof params.get("tkr") === "undefined" ||
          params.get("uid") !== null ||
          typeof params.get("uid") === "undefined" ||
          params.get("token") !== null ||
          typeof params.get("token") === "undefined"
        ) {
          localStorage.setItem("source", "mobile");
          localStorage.setItem("uid", params.get("uid"));
          localStorage.setItem("symbol", params.get("tkr") + "_adj");
          return init();
        } else return showErrorPage();
      } else if (window.location.search.startsWith("?userId")) {
        const paramsObj = new URLSearchParams(window.location.search);
        if (
          paramsObj.get("userId") &&
          paramsObj.get("ticker") &&
          paramsObj.get("token")
        ) {
          const auth = {
            userId: +paramsObj.get("userId"),
            token: paramsObj.get("token"),
            authentication: true,
          };

          const item = {
            auth: JSON.stringify(auth),
          };

          localStorage.removeItem("persist:systemx-lt-");
          localStorage.setItem("persist:systemx-lt-", JSON.stringify(item));

          const isTokenValid = await isUserValid(
            window.btoa(JSON.stringify(auth))
          );
          if (isTokenValid) return init();
          else return showErrorPage();
        } else return showErrorPage();
      }
      else if (window.location.search.startsWith("?token")) {
        const paramsObj = new URLSearchParams(window.location.search);
        if (
          paramsObj.get("userId") !== null ||
          typeof paramsObj.get("userId") === "undefined" ||
          paramsObj.get("token") !== null ||
          typeof paramsObj.get("token") === "undefined"
        ) {
          return init();
        } else return showErrorPage();
      }
      else {
        return showErrorPage();
      }
    } else {
      localStorage.removeItem("persist:systemx-lt-");
      return showErrorPage();
    }
  } catch (e) {
    localStorage.removeItem("persist:systemx-lt-");
    return showErrorPage();
  }
}

storeUserIdToLocalStorage();

function isUserValid(token) {
  return new Promise((resolve, reject) => {
    customAxios
      .get("/navbar/check?tk=" + token)
      .then((result) => result.data)
      .then((result) => {
        if (result === true) resolve(true);
        else {
          reject("Error");
        }
      })
      .catch((ex) => {
        console.log(ex);
        reject("Error");
      });
  });
}

/*
 * TODO : it is returning 0 in token. fixing it.
 *   //FIXED
 * */

function getIdFromTokenSystemx(token) {
  return new Promise((resolve, reject) => {
    axios
      .get(loginBaseURL + "/userIdForCorp?t=" + token)
      .then((result) => {
        return result.data;
      })
      .then((result) => {
        const auth = {
          userId: result.userid,
          authentication: true,
        };
        const item = {
          auth: JSON.stringify(auth),
        };
        localStorage.setItem("userId", result.userid);
        localStorage.removeItem("persist:systemx-lt-");
        localStorage.setItem("persist:systemx-lt-", JSON.stringify(item));
        localStorage.removeItem("symbol");
        if (result.hasOwnProperty("ticker")) {
          localStorage.setItem("symbol", result.ticker);
        } else {
          localStorage.setItem("symbol", "NEPSE");
        }
        resolve(true);
      })
      .catch((ex) => {
        console.log(ex);
        reject(false);
      });
  });
}

function init() {
  const store = createStore(reducer, applyMiddleware(thunk));
  const app = (
    <Provider store={store}>
      <StyleProvider hashPriority="high">
        <ChartRoutes />
      </StyleProvider>
    </Provider>
  );
  console.log("From INIT folder. ");
  ReactDOM.render(app, document.getElementById("tv_chart_container"));
}

function showErrorPage() {
  ReactDOM.render(<NotFound />, document.getElementById("tv_chart_container"));
}
