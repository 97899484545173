import * as actionTypes from "./actionsType";
import { isSystemxLiteRoute } from "../components/Utils/CheckRoute";

const initialState = {
  theme: "",
  symbol: isSystemxLiteRoute() ? "NEPSE" : localStorage.getItem("symbol"),
  isAdjusted: true,
  bearishBullish: {},
  candlePattern: [],
  settingState: {},
  candlePatternId: [],
  bearishBullishId: [],
  portfolioState: {},
  supportResistanceData: [],
  trendLineData: [],
  priceAlertShouldRefetch: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_THEME:
      return {
        ...state,
        theme: action.theme,
      };
    case actionTypes.IS_ADJUSTED:
      return {
        ...state,
        isAdjusted: action.isAdjusted,
      };
    case actionTypes.CLEAR_CANDLE_PATTERN:
      return {
        ...state,
        candlePatternId: action.candlePatternId,
      };
    case actionTypes.CLEAR_BULLISH_BEARISH:
      return {
        ...state,
        bearishBullishId: action.bearishBullishId,
      };
    case actionTypes.CHANGE_SYMBOL:
      return {
        ...state,
        symbol: action.symbol,
      };

    case actionTypes.GET_BULLISH_BEARISH:
      return {
        ...state,
        bearishBullish: action.bullishBearishData,
      };
    case actionTypes.GET_SUPPORT_RESISTANCE_LINE:
      return {
        ...state,
        supportResistanceData: action.supportResistanceData,
      };
    case actionTypes.GET_TREND_LINE:
      return {
        ...state,
        trendLineData: action.trendLineData,
      };
    case actionTypes.CANDLE_PATTERN:
      return {
        ...state,
        candlePattern: action.candlePattern,
      };
    case actionTypes.SAVE_SETTING_STATE:
      return {
        ...state,
        settingState: action.action,
      };
    case actionTypes.SAVE_PORTFOLIO_STATE:
      return {
        ...state,
        portfolioState: action.action,
      };
    case actionTypes.SET_PRICE_ALERT_REFETCH_FLAG: 
      return {
        ...state,
        priceAlertShouldRefetch: action.priceAlertShouldRefetch,
      }
  }

  return state;
};

export default reducer;
