import React, {Suspense} from "react";
import {
    Switch,
    Route,
    Link, BrowserRouter
} from "react-router-dom";
import TVChartContainers from '../SytemxChart/TVChartContainer/NewUpdatedChartContainerSystemx';
import NotFound from "../components/NotFound";
import AppMobile from '../Mobilechart/TVChartContainer/index';
import MultiChartsApp from "../MultiCharts/MultiChartsApp";
import FunctionalStockLive from "../components/SideNav/SideNavTab/StockLive/FunctionalStockLive";

import asyncComponent from "../components/HOC/RouteLoader/RouteLoader";
import axiosOrder from "../axios-order";
import {Routes} from "../components/Utils/CheckRoute";

function getAuth() {
    if (localStorage.getItem("persist:systemx-lt-") !== undefined && localStorage.getItem("persist:systemx-lt-") !== null) {
        return JSON.parse(JSON.parse(localStorage.getItem("persist:systemx-lt-")).auth).userId;
    }
}

export default function ChartRoutes() {

    const Systemx = asyncComponent(() =>
        import('../SytemxChart/App').then(results => {
            localStorage.setItem("source", 'systemx');
            return axiosOrder.get("/navbar/" + Routes.getUserTheme + "?userId=" + getAuth()).then(result => {
                result = result.data;
                localStorage.setItem("tvTheme", result);
                console.log("From Systemx", result);

                return results;
            }).catch(exception => {
                console.log(exception)
                return results;
            })
        }).then(module => module.default));

    const SystemxLite = asyncComponent(() =>
        import('../SystemxLite/App').then(results => {
            localStorage.setItem("source", 'systemxLite');
            return axiosOrder.get("/navbar/" + Routes.getUserTheme + "?userId=" + getAuth()).then(result => {
                result = result.data;
                localStorage.setItem("tvTheme", result);
                return results;
            }).catch(exception => {
                console.log(exception)
                return results;
            })
        }).then(module => module.default)
    )

    const MultiChart = asyncComponent(() => 
        import('../MultiCharts/MultiChartsApp').then(results => {
            localStorage.setItem("source", 'systemxLite');
            return axiosOrder.get("/navbar/"+ Routes.getUserTheme + "?userId=" + getAuth()).then(result => {
                result = result.data;
                localStorage.setItem("tvTheme", result);
                return results;
            }).catch(exception => {
                console.log(exception, 'multichart');
                return results
            })
        }).then(module => module.default)
    )
    const BOIDChart = asyncComponent(() =>
        import('../BOIDBasedChart/TVChartContainer/index').then(results => {
            localStorage.setItem("source", 'mobileChart');
            return results;
        }).then(module => module.default)
    )
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path={"/"} component={AppMobile}/>
                <Route exact path={"/mobileChart"} component={BOIDChart}/>
                <Route exact path={"/test"} component={TVChartContainers}/>
                <Route exact path={"/systemx"} component={Systemx}/>
                <Route exact path={"/systemxlite"} component={SystemxLite}/>
                <Route exact path={"/multichart"} component={MultiChart}/>
                <Route exact path={"/multiCharts"} component={MultiChartsApp} />
                <Route exact path={"/stocklive"} component={FunctionalStockLive} />
                <Route component={NotFound}/>
            </Switch>
        </BrowserRouter>

    );
}

