/* eslint-disable */
import * as React from 'react';
import './index.css';
import {widget} from "../../charting_library";
import {connect} from 'react-redux';
import * as actionTypes from "../../store/actionsType"
import axiosOrder from "../../axios-order";
import {fetchBullishAndBearish, getCandlePatternInfo} from "../../store/actionsType";

function getUrl() {
    const env_var = (typeof window._env_ !== 'undefined') ? window._env_ : process.env
    return env_var.REACT_APP_CHART_URL;
}

function getLanguageFromURL() {
    const regex = new RegExp('[\\?&]lang=([^&#]*)');
    const results = regex.exec(window.location.search);
    return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export function getUserId() {

    try {

        const token = window.location.search.split("?tk=")[1];
        let bytes = window.atob(token);

        let originalText = JSON.parse(bytes);
        return parseInt(originalText.userId);
    } catch (ex) {
        console.log(ex);
    }
}

class TVChartContainer extends React.PureComponent {

    static defaultProps = {
        symbol: 'NEPSE',
        interval: 'D',
        containerId: 'tv_chart_container',
        datafeedUrl: getUrl() + '/tradingViewSystemxLite',
        // datafeedUrl: DataFeed,
        libraryPath: '/charting_library/',
        chartsStorageUrl: getUrl() + '/chart/saveChart',
        chartsStorageApiVersion: '1.1',
        clientId: 'systemx.tradingview.com',
        userId: getUserId(),
        fullscreen: true,
        autosize: true,
        studiesOverrides: {},
        timezone: "Asia/Kathmandu",
        width: 500,
        height: 500
    };
    tvWidget = null;

    constructor(props) {
        super(props);
        this.state = {
            isClearMarks: true,
            candlePattern: []
        }
        this.getCandlePatternState = this.getCandlePatternState.bind(this);
    }

    getCandlePatternState(symbol) {
        axiosOrder.get("/tradingViewSystemxLite/getCandlePattern?symbol=" + symbol).then(result => result.data).then(result => {
            this.setState({candlePattern: result})
        }).catch(err => {
            console.log(err);
            this.setState({candlePattern: []})
        })
    }


    componentDidMount() {

        // this.tvWidget.changeTheme(localStorage.getItem("tvTheme"));
        const widgetOptions = {
            symbol: this.props.symbol,
            // BEWARE: no trailing slash is expected in feed URL
            datafeed: new window.Datafeeds.UDFCompatibleDatafeed(this.props.datafeedUrl),
            // datafeed: DataFeed,
            interval: this.props.interval,
            container_id: this.props.containerId,
            library_path: this.props.libraryPath,

            locale: getLanguageFromURL() || 'en',
            disabled_features: ['use_localstorage_for_settings'],
            enabled_features: ['study_templates'],
            charts_storage_url: this.props.chartsStorageUrl,
            charts_storage_api_version: this.props.chartsStorageApiVersion,
            client_id: this.props.clientId,
            user_id: this.props.userId,
            fullscreen: this.props.fullscreen,
            autosize: this.props.autosize,
            studies_overrides: this.props.studiesOverrides,
            timezone: "Asia/Kathmandu",
            theme: localStorage.getItem("tvTheme"),

        };

        const tvWidget = new widget(widgetOptions);
        this.tvWidget = tvWidget;


        tvWidget.onChartReady(() => {
            tvWidget.headerReady().then(() => {
                // tvWidget.changeTheme(this.props.theme);
                document.querySelector("#loadChart").addEventListener("click", () => {
                    tvWidget.activeChart().clearMarks();
                    tvWidget.activeChart().refreshMarks();
                })

                const button = tvWidget.createButton();
                button.setAttribute('title', 'Click to show a notification popup');
                button.classList.add('apply-common-tooltip');

                button.addEventListener('click', () => {
                    const currentSymbol = tvWidget.activeChart().symbol()
                    if (currentSymbol.includes('_adj')) {
                        const stockSymbol = currentSymbol.split('_')[0]
                        tvWidget.activeChart().setSymbol(stockSymbol)
                        button.innerHTML = 'Adjusted';
                    } else {
                        tvWidget.activeChart().setSymbol(currentSymbol + "_adj");
                        button.innerHTML = 'Unadjusted';
                    }
                })
                button.innerHTML = 'Adjusted';
            });

            tvWidget.activeChart().onSymbolChanged().subscribe(null, () => {
                this.props.onSymbolChanged(tvWidget.activeChart().symbol())
            });

        });
    }

    isEmpty(obj) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    checkStatusOfTickerDivergence() {
        const divergence = this.props.settingState;
        if (divergence.rsiBullish) {
            setTimeout(() => this.props.fetchDivergence(this.props.symbol, "rsiBullish"), 100);
        } else if (divergence.cciBullish) {
            setTimeout(() => this.props.fetchDivergence(this.props.symbol, "cciBullish"), 100);
        } else if (divergence.mfiBullish) {
            setTimeout(() => this.props.fetchDivergence(this.props.symbol, "mfiBullish"), 100);
        } else if (divergence.rsiBearish) {
            setTimeout(() => this.props.fetchDivergence(this.props.symbol, "rsiBearish"), 100);
        } else if (divergence.cciBearish) {
            setTimeout(() => this.props.fetchDivergence(this.props.symbol, "cciBearish"), 100);
        } else if (divergence.mfiBearish) {
            setTimeout(() => this.props.fetchDivergence(this.props.symbol, "mfiBearish"), 100);
        } else {
            setTimeout(() => this.props.fetchDivergence(this.props.symbol, ""), 100);
        }
    }

    removeCandlePatternBeforeAnotherPlot() {
        let myCandlePattern = this.props.candlePatternId;
        myCandlePattern.forEach(item => {
            this.tvWidget.activeChart().removeEntity(item);
        })
    }

    removeTrendLineBeforeAnotherPlot() {
        let myTrendLine = this.props.bearishBullishId;
        myTrendLine.forEach(item => {
            this.tvWidget.activeChart().removeEntity(item);
        })

    }

    findChannel(state) {
        if (state.cciBearish || state.mfiBearish) {
            return 'high';
        } else if (state.cciBullish || state.mfiBullish) {
            return "low"
        } else {
            return 'close';
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        try {
            if (prevProps.theme !== this.props.theme) {
                this.tvWidget.changeTheme(localStorage.getItem("tvTheme"));
            }
            if (prevProps.symbol !== this.props.symbol) {
                this.tvWidget.activeChart().setSymbol(this.props.symbol)
                if (this.props.settingState.candlePattern === true) {
                    setTimeout(() => this.props.getCandlePattern(this.props.symbol), 200);
                } else {
                    setTimeout(() => this.props.getCandlePattern(""), 100);
                }
                this.checkStatusOfTickerDivergence();
            }
            if (prevProps.bearishBullish !== this.props.bearishBullish) {
                try {
                    const bearishBullishData = this.props.bearishBullish;
                    if (!this.isEmpty(bearishBullishData)) {
                        // this.tvWidget.activeChart().removeAllShapes();
                        this.removeTrendLineBeforeAnotherPlot();

                        const channelName = this.findChannel(this.props.settingState);
                        const from = bearishBullishData.from;
                        const to = bearishBullishData.to;
                        let localStorageForTrendLine = [];
                        const trendLine = this.tvWidget.activeChart().createMultipointShape(
                            [{time: from, channel: channelName}, {time: to, channel: channelName}],
                            {
                                shape: "trend_line",
                                showInObjectsTree: true,
                                overrides: {
                                    bold: true,
                                    linecolor: "#228ad0",
                                    linewidth: 6,
                                    rightEnd: 2,
                                    showBarsRange: true,
                                    showPriceRange: true,
                                    showDateTimeRange: true,
                                    textcolor: "red",
                                    leftEnd: 2,
                                    italic: true,
                                    fontSize: 14,
                                    showLabel: true,
                                }
                            }
                        );
                        localStorageForTrendLine.push(trendLine);
                        this.props.clearBullishBearish(localStorageForTrendLine);
                    } else {
                        // this.removingShapesFromChart();
                        this.removeTrendLineBeforeAnotherPlot();
                    }
                } catch (e) {
                    // this.tvWidget.activeChart().removeAllShapes();
                }
            }
            if (this.props.candlePattern !== prevProps.candlePattern) {
                try {
                    let candlePattern = [...this.props.candlePattern];
                    if (candlePattern.length !== 0) {
                        const localStorageForCandlePattern = [];
                        // this.tvWidget.activeChart().removeAllShapes();
                        this.removeCandlePatternBeforeAnotherPlot();
                        for (let i = 0; i < candlePattern.length; i++) {
                            const pattern = this.tvWidget.activeChart().createMultipointShape([{time: candlePattern[i].time}], {
                                shape: "text",
                                text: candlePattern[i].text,
                                overrides: {
                                    backgroundColor: "#B0B5B8",
                                    bold: true,
                                    showLabel: "text",
                                    transparency: 20,
                                    fillBackground: true,
                                    borderColor: "red",
                                    color: "blue",
                                    fontsize: 10,
                                }
                            });
                            localStorageForCandlePattern.push(pattern)
                            // this.tvWidget.activeChart().removeEntity(test);
                            this.tvWidget.activeChart().getShapeById(pattern).bringToFront();
                        }
                        this.props.clearCandlePattern(localStorageForCandlePattern);
                    } else {
                        // this.removingShapesFromChart();
                        this.removeCandlePatternBeforeAnotherPlot();

                    }
                } catch (e) {
                    // this.tvWidget.activeChart().removeAllShapes();
                }
            }
        } catch (ex) {
            console.log(ex);
        }
    }


    componentWillUnmount() {
        if (this.tvWidget !== null) {
            this.tvWidget.remove();
            this.tvWidget = null;
        }
    }

    render() {
        return (
            <div id={this.props.containerId}
                 className={'TVChartContainer'}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        symbol: state.symbol,
        theme: state.theme,
        bearishBullish: state.bearishBullish,
        candlePattern: state.candlePattern,
        settingState: state.settingState,
        bearishBullishId: state.bearishBullishId,
        candlePatternId: state.candlePatternId
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getUserTheme: (userTheme) => dispatch({type: actionTypes.CHANGE_THEME, theme: userTheme}),
        onSymbolChanged: (symbol) => dispatch({type: actionTypes.CHANGE_SYMBOL, symbol: symbol}),
        fetchDivergence: (symbol, technicalName) => dispatch(fetchBullishAndBearish(symbol, technicalName)),
        getCandlePattern: (symbol) => dispatch(getCandlePatternInfo(symbol)),
        saveSettingState: (settingState) => dispatch({type: actionTypes.SAVE_SETTING_STATE, action: settingState}),
        clearCandlePattern: (candlePatternId) => dispatch({
            type: actionTypes.CLEAR_CANDLE_PATTERN,
            candlePatternId: candlePatternId
        }),
        clearBullishBearish: (bullishBearishId) => dispatch({
            type: actionTypes.CLEAR_BULLISH_BEARISH,
            bearishBullishId: bullishBearishId
        }),

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TVChartContainer);
