import axios from "axios";

function getUrl() {
  const env_var =
    typeof window._env_ !== "undefined" ? window._env_ : process.env;
  return env_var.REACT_APP_CHART_URL;
}

let token;

if (window.location.search.split("?tk=")) {
  token = window.location.search.split("?tk=")[1];
} else {
  token = window.location.search.split("?t=")[1];
}

const axiosOrder = axios.create({
  baseURL: getUrl(),
  headers: {
    Authorization: token || "NO TOKEN AVAILABLE",
  },
});
export default axiosOrder;
